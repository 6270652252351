import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

interface IProps {
  content: string;
}

const MarkdownMessage: React.FC<IProps> = ({ content }) => {
  return (
    <div>
      {content && (
        <ReactMarkdown
          remarkPlugins={[remarkGfm]} // Supports GitHub Flavored Markdown
          rehypePlugins={[rehypeRaw]} // Allows raw HTML in the Markdown
          components={{
            a: ({ href, children, ...props }) => (
              <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                {children}
              </a>
            ),
            p: ({ children }) => <>{children}</>,
          //@ts-ignore
            code: ({ inline, children, ...props }) => {
              if (inline) {
                return <code className="language-js" {...props}>{children}</code>;
              }
              return (
                <pre>
                  <code className="language-js" {...props}>{children}</code>
                </pre>
              );
            },
            ul: ({ children }) => (
              <ul style={{ height: 'fit-content', width: 'fit-content' }}>{children}</ul>
            ),
            ol: ({ children }) => <ol>{children}</ol>,
            li: ({ children }) => <li>{children}</li>,
          }}
        >
          {content}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default memo(MarkdownMessage);
